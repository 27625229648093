// Couleurs
:root {
  --primary: #0070BA;
  --white: #FFFFFF;
}

// Ombre du header
header.ant-layout-header {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

// Footer
.ant-layout-footer {
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  a {
    color: var(--white);

    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    text-indent: 0;
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 10px;
    }
  }
}

// Souligner les options du menu au survol
.ant-menu-item:hover .ant-menu-title-content {
  text-decoration: underline;
}

// Ajoute le background manquant à l'option Compte utilisateur + avatar si le viewport est trop étroit en desktop
.ant-menu-dark>.ant-menu .ant-menu-submenu-selected>.ant-menu-submenu-title {
  background-color: var(--white) !important;
}

// Couleurs de l'Avatar dans le menu
.ant-menu {
  .ant-menu-submenu {
    .ant-avatar {
      background-color: var(--white);
      color: var(--primary);

      .ant-avatar-string {
        color: var(--primary)
      }
    }
  }

  .ant-menu-submenu-selected {
    .ant-avatar {
      background-color: var(--primary);

      svg,
      .ant-avatar-string {
        color: var(--white) !important
      }
    }
  }
}

// Label des descriptions en gras
.ant-descriptions {
  .ant-descriptions-item-label {
    font-weight: 700;
  }
}