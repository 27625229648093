@import "config";


.blue-form {
  .ant-form-item-label label {
    color: $blue;
    font-size: 18px;
    font-weight: 500;
  }

  .ant-tooltip-inner {
    background-color: $blue;
  }

  .ant-tooltip-arrow {
    --antd-arrow-background-color: #006FBA;
  }
}

.ant-picker-panel-container {
  background: $white !important;
}

// Collapse

.hide-collapse-header .ant-collapse-header {
  display: none !important;
}

.hide-collapse-header .ant-collapse-content-box {
  padding: 0 !important;
}

//Alert
.ant-alert-icon {
  font-size: 16px !important;
}

.ant-alert {
  display: flex;
  align-items: center;
}

.ant-alert-description {
  font-size: 12px;
}

// Custom Table
.separate-rows-table {
  .ant-table {
    background: transparent;
  }

  .ant-table-body>table {
    border-collapse: separate;
    border-spacing: 0 19px;
    margin-top: -12px;
  }

  .ant-table-cell {
    background-color: $white;
  }
}

.blue-header-table {
  .ant-table-thead>tr th {
    background-color: $blue !important;
    color: $white !important;

    &.ant-table-column-sort::before {
      background-color: #e0d9d4 !important;
    }

    .ant-table-column-sorter {
      color: rgba(255, 255, 255, 0.1);
      & .active {
        color: $white;
      }
    }

    &:hover {
      .ant-table-column-sorter {
        color: rgba(255, 255, 255, 0.5);
      }
      &.ant-table-column-has-sorters::before {
        background-color: #e0d9d4 !important;
      }
    }
  }
}

.custom-rows-expanded {
  .ant-table-expanded-row {
    transform: translateY(-19px);

    >td {
      background-color: rgba(218, 218, 218, 0.40) !important;
    }
  }
}

.no-padding-popover {
  .ant-popover-inner {
    padding: 0;
  }
}

.white-popover {
  .ant-popover-inner {
    background-color: $white;
  }

  .ant-popover-arrow::before {
    background-color: $white;
  }
}

// Place l'astérisque des champs obligatoires après le label
.ant-form-item-required {
  flex-direction: row-reverse;

  &::before {
    margin-left: 4px;
  }

  &::after {
    width: 0;
    margin: 0 !important;
  }
}

// Ajoute un espace avant les ":" des labels pour les Descriptions
.ant-descriptions .ant-descriptions-item-label::after {
  content: ' :';
}

// MetricsCard
.metrics-card {
  .ant-card-body {
    height: 100%;
    display: flex;
    justify-items: center;
    align-items: center;
  }
}

// Calculer une prime
.calculateur-tabs {
  .ant-tabs-tab {
    &-btn {
      @apply text-dark-gray-300 !important;
      @apply text-xl !important;

      &:hover {
        @apply text-gray-950 !important;
      }
    }

    &-active {
      .ant-tabs-tab-btn {
        @apply text-gray-950 !important;
      }
    }
  }
}